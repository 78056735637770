
@if $theme-default { 

    [data-theme="default"] { 
        
        //Typography

        // Font Family
        --#{$prefix}font-family-primary:                                     'hkgrotesk', sans-serif;
        --#{$prefix}font-family-secondary:                                   'Poppins', sans-serif;
        
        $font-sizes: (
            "10":          0.625rem,
            "11":          0.6875rem,
            "12":          0.75rem,
            "13":          0.8125rem,
            "14":          0.875rem,
            "base":        0.8125rem, //13px
            "15":          0.9375rem,
            "16":          1rem,
            "17":          1.0625rem,
            "18":          1.125rem,
            "19":          1.1875rem,
            "20":          1.25rem,
            "21":          1.3125rem,
            "22":          1.375rem,
            "23":          0.8125rem,
            "24":          1.5rem,            
            "36":          2.25rem,
            "48":          3rem,
        );

        $font-weights: (
            "light"         300,
            "normal"        400,
            "medium"        500,
            "semibold"      600,
            "bold"          700,
        );

        --#{$prefix}grid-gutter-width:                              1.5rem;

        --#{$prefix}headings-font-weight:                           500;

        --#{$prefix}element-shadow:                                 none;

        //cards
        --#{$prefix}card-border-width-custom:                       0;
        --#{$prefix}card-header-border-width:                       1px;
        --#{$prefix}card-shadow:                                    #{$box-shadow};

        
        //sidebar menu
        &:root {
            --#{$prefix}vertical-menu-item-font-family:                     var(--#{$prefix}font-family-primary);
            --#{$prefix}vertical-menu-sub-item-font-family:                 var(--#{$prefix}font-family-secondary);
        }
        --#{$prefix}headings-font-family:                               var(--#{$prefix}font-family-secondary);
        --#{$prefix}vertical-menu-item-font-size:                       0.9375rem;
        --#{$prefix}vertical-menu-sub-item-font-size:                   0.813rem;

        --#{$prefix}vertical-menu-bg:                                   #{$white};
        --#{$prefix}vertical-menu-border:                               #{$white};
        --#{$prefix}vertical-menu-item-color:                           #{darken($gray-600, 10%)};
        --#{$prefix}vertical-menu-item-bg:                              #{rgba(var(--#{$prefix}primary-rgb), .15)};
        --#{$prefix}vertical-menu-item-hover-color:                     var(--#{$prefix}primary);
        --#{$prefix}vertical-menu-item-active-color:                    var(--#{$prefix}primary);
        --#{$prefix}vertical-menu-item-active-bg:                       #{rgba(var(--#{$prefix}primary-rgb), .15)};
        --#{$prefix}vertical-menu-sub-item-color:                       #{darken($gray-600, 4%)};
        --#{$prefix}vertical-menu-sub-item-hover-color:                 var(--#{$prefix}primary);
        --#{$prefix}vertical-menu-sub-item-active-color:                var(--#{$prefix}primary);
        --#{$prefix}vertical-menu-title-color:                          #919da9;
        --#{$prefix}vertical-menu-box-shadow:                           0 2px 4px rgba(15, 34, 58, 0.12);
        --#{$prefix}vertical-menu-dropdown-box-shadow:                  0 2px 4px rgba(15, 34, 58, 0.12);
        --#{$prefix}sidebar-user-bg:                                    #{$light};
        --#{$prefix}sidebar-user-name-text:                             #{darken($gray-700, 10%)};
        --#{$prefix}sidebar-user-name-sub-text:                         #{darken($gray-600, 4%)};

        &[data-sidebar="dark"] {
            //vertical dark
            --#{$prefix}vertical-menu-bg:                               var(--#{$prefix}primary);
            --#{$prefix}vertical-menu-border:                           var(--#{$prefix}primary);
            --#{$prefix}vertical-menu-item-color:                       #abb9e8;
            --#{$prefix}vertical-menu-item-bg:                          #{rgba($white, .15)};
            --#{$prefix}vertical-menu-item-hover-color:                 #{$white};
            --#{$prefix}vertical-menu-item-active-color:                #{$white};
            --#{$prefix}vertical-menu-item-active-bg:                   #{rgba($white, .15)};
            --#{$prefix}vertical-menu-sub-item-color:                   #abb9e8;
            --#{$prefix}vertical-menu-sub-item-hover-color:             #{$white};
            --#{$prefix}vertical-menu-sub-item-active-color:            #{$white};
            --#{$prefix}vertical-menu-title-color:                      #838fb9;
            --#{$prefix}twocolumn-menu-iconview-bg:                     #{darken($primary, 2%)};
            --#{$prefix}vertical-menu-box-shadow:                       0 2px 4px rgba(15, 34, 58, 0.12);
            --#{$prefix}vertical-menu-dropdown-box-shadow:              0 2px 4px rgba(15, 34, 58, 0.12);
            --#{$prefix}sidebar-user-bg:                                #{rgba($white, .08)};
            --#{$prefix}sidebar-user-name-text:                         #{$white};
            --#{$prefix}sidebar-user-name-sub-text:                     #{tint-color($primary, 65%)};
        }

        // Topbar - (Default Light)
        --#{$prefix}header-bg:                                          #{$white};
        --#{$prefix}header-border:                                      #{$border-color};
        --#{$prefix}header-item-color:                                  #{$gray-700};
        --#{$prefix}header-item-bg:                                     #{rgba($secondary, .12)};
        --#{$prefix}header-item-sub-color:                              #878a99;
        --#{$prefix}topbar-search-bg:                                   #f3f3f9;
        --#{$prefix}topbar-user-bg:                                     #f3f3f9;
        --#{$prefix}topbar-search-color:                                #{$gray-700};
        
        // Topbar - Dark
        &[data-topbar="dark"] {
            --#{$prefix}header-bg:                                      var(--#{$prefix}primary);
            --#{$prefix}header-border:                                  var(--#{$prefix}primary);
            --#{$prefix}header-item-color:                              #{rgba($white, .85)};
            --#{$prefix}header-item-bg:                                 #495c99;
            --#{$prefix}header-item-sub-color:                          #b0c4d9;
            --#{$prefix}topbar-user-bg:                                 #52639c;
            --#{$prefix}topbar-search-bg:                               #{rgba($white, .05)};
            --#{$prefix}topbar-search-color:                            #{$white};
        }

        &[data-sidebar="gradient"] {
            --#{$prefix}vertical-menu-bg:                               #{linear-gradient(to right, var(--#{$prefix}primary), var(--#{$prefix}success))};
            --#{$prefix}vertical-menu-border:                           var(--#{$prefix}success);
            --#{$prefix}twocolumn-menu-iconview-bg:                     var(--#{$prefix}primary);
        }
        
        &[data-sidebar="gradient-2"] {
            --#{$prefix}vertical-menu-bg:                               #{linear-gradient(to right, var(--#{$prefix}info), var(--#{$prefix}secondary))};
            --#{$prefix}vertical-menu-border:                           var(--#{$prefix}secondary); 
            --#{$prefix}twocolumn-menu-iconview-bg:                     var(--#{$prefix}info);
        }
        
        &[data-sidebar="gradient-3"] {
            --#{$prefix}vertical-menu-bg:                               #{linear-gradient(to right, var(--#{$prefix}info), var(--#{$prefix}success))};
            --#{$prefix}vertical-menu-border:                           var(--#{$prefix}success);
            --#{$prefix}twocolumn-menu-iconview-bg:                     var(--#{$prefix}info);
        }
        
        &[data-sidebar="gradient-4"] {
            --#{$prefix}vertical-menu-bg:                               #{linear-gradient(to right, #1a1d21, var(--#{$prefix}primary))};
            --#{$prefix}vertical-menu-border:                           var(--#{$prefix}primary);
            --#{$prefix}twocolumn-menu-iconview-bg:                     #1a1d21;
        }

        //page title 
        --#{$prefix}page-title-box-shadow:                              0 1px 2px rgba(56, 65, 74, 0.15);
        --#{$prefix}page-title-border:                                  none;

        //footer
        --#{$prefix}footer-bg:                                          #{$white};
        --#{$prefix}footer-color:                                       #98a6ad;

        //Boxed layout
        --#{$prefix}boxed-body-bg:                                      #e5e5f2;
        
        --#{$prefix}timeline-color:                                     var(--#{$prefix}secondary-bg);
        
        --#{$prefix}chat-primary-bg:                                    var(--#{$prefix}light);
        --#{$prefix}chat-secondary-bg:                                  rgba(var(--#{$prefix}success-rgb), 0.15);
        --#{$prefix}chat-secondary-color:                               var(--#{$prefix}success);

        @each $name, $value in $font-sizes { 
            --#{$prefix}font-#{$name}:          #{$value};
        }
    
        @each $name, $value in $font-weights { 
            --#{$prefix}font-weight-#{$name}:   #{$value};
        }

        &[data-theme-colors="green"] {
            --#{$prefix}primary:                                        #{$green-700};
            --#{$prefix}primary-rgb:                                    #{to-rgb($green-700)};
            --#{$prefix}primary-bg-subtle:                              #{rgba($green-700, 0.15)};
            --#{$prefix}primary-border-subtle:                          #{rgba($green-700, 0.30)};
            --#{$prefix}primary-text-emphasis:                          #{shade-color($green-700, 25%)};
        }

        &[data-theme-colors="purple"] {
            --#{$prefix}primary:                                        #{$purple-600};
            --#{$prefix}primary-rgb:                                    #{to-rgb($purple-600)};
            --#{$prefix}primary-bg-subtle:                              #{rgba($purple-600, 0.15)};
            --#{$prefix}primary-border-subtle:                          #{rgba($purple-600, 0.30)};
            --#{$prefix}primary-text-emphasis:                          #{shade-color($purple-600, 25%)};
        }

        &[data-theme-colors="blue"] {
            --#{$prefix}primary:                                        #{$blue-600};
            --#{$prefix}primary-rgb:                                    #{to-rgb($blue-600)};
            --#{$prefix}primary-bg-subtle:                              #{rgba($blue-600, 0.15)};
            --#{$prefix}primary-border-subtle:                          #{rgba($blue-600, 0.30)};
            --#{$prefix}primary-text-emphasis:                          #{shade-color($blue-600, 25%)};
        }

        .card-radio {
            .form-check-label {
                &[for="themeColor-01"] {
                    background-color: $primary;
                }

                &[for="themeColor-02"] {
                    background-color: $green-700; 
                }

                &[for="themeColor-03"] {
                    background-color: $purple-600; 
                }

                &[for="themeColor-04"] {
                    background-color: $blue-600; 
                }
            }
        }
    }
}